<template>
  <div
    ref="divRef"
    class="search_container rounded-lg d-flex-center flex-column"
    :style="`min-height: ${heightCard}; background-image: url(${backgroundTrainSearchUrl})`"
  >
    <div class="search_background">
      <p
        :class="`fw-800
         ${isMobileView ? 'font-large-1 mb-2' : 'font-large-2 mb-3'}
        `"
      >
        {{ $t('train.search.searchTrain') }}
      </p>

      <SearchType :type.sync="searchData.tripType" />

      <div class="search-stop-point w-100 d-flex flex-wrap flex-md-nowrap align-items-center mb-2">
        <!-- ANCHOR From -->
        <b-card
          class="flex-grow-1 mb-1 mb-md-0 w-100 p-75"
          no-body
        >
          <div
            class="text-body"
            style="margin-left: 7.5px"
          >
            {{ $t('flight.startFrom') }}
          </div>
          <SearchStopPoint :data-prop.sync="searchData.from" />
        </b-card>

        <!-- ANCHOR switch -->
        <div class="switch_airport mx-auto mx-md-2 my-lg-1 flex-shrink-1">
          <b-button
            v-ripple.100
            variant="primary"
            class="btn-icon rounded-circle p-2 p-lg-1 border"
            @click="swapFromToHandle"
          >
            <IAmIcon
              icon="swap"
              class="d-flex-center text-white"
              size="20px"
            />
          </b-button>
        </div>

        <!-- ANCHOR TO -->
        <b-card
          class="flex-grow-1 mb-0 w-100 p-75"
          no-body
        >
          <div
            class="text-body"
            style="margin-left: 7.5px"
          >
            {{ $t('flight.endAt') }}
          </div>
          <SearchStopPoint
            :data-prop.sync="searchData.to"
            right
          />
        </b-card>
      </div>

      <b-card
        class="mb-2"
        body-class="p-1 d-flex-center flex-column flex-md-row"
      >
        <!-- ANCHOR Search Fare -->
        <div
          class="d-flex align-items-center divider-after"
          style="height: 37.98px"
        >
          <b-form-checkbox
            v-model="isSearchFares"
            class="custom-control-success my-auto"
            switch
          >
            <div class="text-nowrap font-medium-2 text-body fw-800">
              {{ $t('train.search.searchFare') }}
            </div>
          </b-form-checkbox>
        </div>

        <!-- ANCHOR Depart Date -->
        <div
          :class="`d-flex align-items-center
              ${['RT'].includes(searchData.tripType) ? 'divider-after' : ''}
            `"
        >
          <SearchDatePicker
            :date.sync="searchData.departDate"
            :config="{
              minDate: 'today',
            }"
            class="w-100 fw-800"
            :icon="'@icons/airplane-up.svg'"
            @input="handleDepartDate"
          >
            <template #prefix>
              <IAmIcon
                icon="calendar"
                size="22"
              />
              <span class="mx-50 text-nowrap font-medium-2">{{ $t('train.search.departureDate') }}</span>
            </template>
          </SearchDatePicker>
        </div>

        <!-- ANCHOR Return Date -->
        <div :class="`d-flex align-items-center`">
          <SearchDatePicker
            v-if="['RT'].includes(searchData.tripType)"
            :date.sync="searchData.returnDate"
            :config="{
              minDate: searchData.departDate,
            }"
            class="w-100 fw-800"
          >
            <template #prefix>
              <IAmIcon
                icon="calendar"
                size="22"
              />
              <span class="mx-50 text-nowrap font-medium-2">{{ $t('train.search.returnDate') }}</span>
            </template>
          </SearchDatePicker>
        </div>
      </b-card>

      <!-- <b-card
        class="mb-2"
        body-class="p-1 d-flex-center flex-column flex-md-row gap-1"
      > -->
      <!-- ANCHOR Passenger -->
      <!-- <SearchPassenger
          :adult.sync="searchData.adult"
          :child.sync="searchData.child"
          :infant.sync="searchData.infant"
          class="flex-fill w-100"
        /> -->

      <!-- <b-col
          cols="12"
          md="4"
        >
          <SearchPromoCode
            :promo-codes.sync="searchData.promoCodes"
            class="d-flex-center flex-fill w-100"
          />
        </b-col>
      </b-card> -->

      <div class="w-100 d-flex-center mt-2">
        <b-button
          v-ripple.100
          variant="primary"
          class="px-3"
          pill
          @click="handleSearch"
        >
          <div class="d-flex-center font-medium-2">
            <img
              src="@icons/search.svg"
              alt="Search"
              class="mr-25 ico-bold"
            >
            {{ $t('train.search.search') }}
          </div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onBeforeMount } from '@vue/composition-api'
import { BButton, BCard, BFormCheckbox } from 'bootstrap-vue'
import moment from 'moment'

import store from '@/store'
import { useRouter } from '@/@core/utils/utils'
import env from '@/libs/env'

import useTrainHandle from '@train/useTrainHandle'

import useToast from '@useToast'

export default {
  components: {
    BButton,
    BCard,
    BFormCheckbox,

    SearchType: () => import('./components/SearchType.vue'),
    SearchStopPoint: () => import('./components/SearchStopPoint.vue'),
    SearchDatePicker: () => import('./components/SearchDatePicker.vue'),

    // SearchPassenger: () => import('./components/SearchPassenger.vue'),
    // SearchPromoCode: () => import('./components/SearchPromoCode.vue'),
  },
  setup() {
    const { router } = useRouter()
    const { toastWarning } = useToast()

    const {
      loading,
      stateCategory,
      stateSetting,
      defaultDeparture,
      defaultArrival,
      getListTrainStation,
    } = useTrainHandle()

    onBeforeMount(() => {
      if (!stateCategory.value || !getListTrainStation.value) {
        store.dispatch('app-train/handleGetCategory')
      }
      if (!stateSetting.value) {
        store.dispatch('app-train/handleGetSetting')
      }
    })

    const today = moment().format('YYYY-MM-DD')
    const isSearchFares = ref(false)

    const searchData = ref({
      promoCodes: [],
      from: defaultDeparture,
      to: defaultArrival,
      departDate: moment.utc(today).add(1, 'days').format('YYYY-MM-DD'),
      returnDate: moment.utc(today).add(6, 'days').format('YYYY-MM-DD'),
      bookingCode: '',
      tripType: 'OW',
    })

    function modifyDataSearch() {
      const { from, to, ...rest } = searchData.value
      const payload = {
        ...rest,
        departure: from.code,
        arrival: to.code,
      }
      return JSON.stringify(payload)
    }

    function handleSearch() {
      if (['RT'].includes(searchData.value.tripType) && !searchData.value.returnDate) {
        toastWarning({ title: 'Vui lòng chọn ngày về !' })
        return
      }
      localStorage.setItem('trainSearch', modifyDataSearch())
      const namePath = isSearchFares.value ? 'apps-trainResultSearchFares' : 'apps-trainResultSearch'
      router.push({ name: namePath })
    }

    function swapFromToHandle() {
      const temp = searchData.value.from
      searchData.value.from = searchData.value.to
      searchData.value.to = temp
    }

    function handleDepartDate(date) {
      const departDate = new Date(date)
      if (departDate > new Date(searchData.value.returnDate)) {
        searchData.value.returnDate = moment.utc(departDate).add(3, 'days').format('YYYY-MM-DD')
      }
    }

    const divRef = ref()
    const heightCard = computed(() => {
      const offsetTop = divRef.value?.offsetTop ?? 0
      return offsetTop ? `calc(100vh - ${offsetTop}px - 10px)` : '70vh'
    })

    return {
      defaultDeparture,
      defaultArrival,
      searchData,
      loading,
      isSearchFares,

      // Handle
      handleSearch,
      swapFromToHandle,
      handleDepartDate,
      divRef,
      heightCard,
      backgroundTrainSearchUrl: env.backgroundTrainSearchUrl,
    }
  },
}
</script>

<style lang="scss" scoped>
.search_container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.search_background {
  background: rgba(255, 255, 255, 0.27);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  width: 1136px;
  max-width: calc(100% - 24px);
  padding: 40px
}

@media (max-width: 768px) {
  .search_background {
    padding: 12px
  }
}

.search-stop-point {
  position: relative;

  .switch_airport {
    @media (max-width: 767px) {
      position: absolute;
      z-index: 99;
      right: -50px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.divider-after {
    &::after {
      content: "";
      display: inline-block;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #000000;
      height: 28px;
      margin: 0 1rem;

      @media (max-width: 768px) {
        content: none;
      }
    }
  }
</style>
